const $clone = require('lodash.clone')
const $merge = require('lodash.merge')
const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')
const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next'),
}
let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null
if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}
const findTag = (slug, tags) => {
  return tags.find((tag) => tag.slug === slug)
}
const transformConfig = (config) => {
  if (config.useShell) {
    config = loadShell(config)
  }
  return config
}
/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge(
  {},
  config,
  {
    /**
     * common configuration here
     */
    siteId: '5f0c56430fc1bc83c36f23a0',
    authMode: [['local']],
    enableSplashScreen: 'wavy',
    enableExperimentalBlocks: true,
    showCardDescription: true,
    disabledComponents: [
      'wTagLikeCount',
      'wButtonLike',
      'wCardsLikeLabels',
      'wCardsCommentsLabels',
    ],
    metadatas: {
      name: 'Patients Ensemble',
      colorPrimary: '#ea541e',
      colorAccent: '#69605d',
    },
    analytics: {
      matomo: '6',
    },
    hasAutoplayContent: true,
    hasSuggestedContent: true,
    useShell: [
      'bb-default',
      {
        mode: 'universes',
        universes: [
          {
            name: 'collection:podcast',
            type: 'tag',
            tagId: '5f27e4b60fc1bc228f6f5abd',
            i18n: {
              fr: {
                title: `Podcasts`,
                description: `Vos podcasts Patients Ensemble`,
              },
              en: {
                title: `Podcast`,
                description: `podcast`,
              },
            },
            heading: {
              canRadio: false,
              color: '#eb5c26',
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              hideTitle: true,
              backgroundImage(context) {
                return context.$voicer.isMobile
                  ? require('~/assets/images/podcast-mobile.png')
                  : require('~/assets/images/podcast.png')
              },
            },
            filters: {
              type: 'solo',
              soloTagId: '5f27e5750fc1bc328a6f5ace',
              sort: (category) => {
                // seulement et dans cet ordre :
                // Cancers, Maladies chroniques, Maladies rares, Autres
                // 1) on clone les tags
                const clonedCategory = $clone(category)
                const subTags = clonedCategory.tags
                const displaidTags = []
                displaidTags.push(findTag('cancers', subTags))
                displaidTags.push(findTag('maladies-chroniques', subTags))
                displaidTags.push(findTag('maladies-rares', subTags))
                displaidTags.push(findTag('autres', subTags))
                displaidTags.push(findTag('evenements', subTags))

                clonedCategory.tags = displaidTags.filter(Boolean)
                return clonedCategory
              },
            },
          },
          {
            name: "Patient's Side of the story",
            type: 'tag',
            tagId: '6509aeef213a2f9da88c86de',

            i18n: {
              fr: {
                title: "Patient's Side of the story",
                description: 'Stories by Servier Saclay Patient Board',
              },
            },
            heading: {
              canRadio: false,
              color: '#D46517',
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              hideTitle: true,
            },
          },
          {
            name: 'AirZen Radio',
            type: 'tag',
            tagId: '627a37fee229701bd3b67bed',

            i18n: {
              fr: {
                title: 'AirZen Radio',
                description: 'Vos podcasts bien-être',
              },
            },
            heading: {
              canRadio: false,
              color: '#f6a130',
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              hideTitle: true,
              backgroundImage(context) {
                return context.$voicer.isMobile
                  ? require('~/assets/images/airzen-mobile.png')
                  : require('~/assets/images/airzen.png')
              },
            },
          },
          {
            name: 'collection:article',
            type: 'tag',
            tagId: '606c7d211a01717254454ff3',

            i18n: {
              fr: {
                title: 'Articles',
                description: 'Votre coin lecture',
              },
            },
            heading: {
              canRadio: false,
              color: '#F9B74B',
              dark: true,
              buttonColor: 'white',
              buttonLight: true,
              hideTitle: true,
              backgroundImage(context) {
                return context.$voicer.isMobile
                  ? require('~/assets/images/article-mobile.png')
                  : require('~/assets/images/article.png')
              },
            },
            filters: {
              type: 'solo',
              soloTagId: '6298c6fc6293f63b34aa8765',
              sort: (category) => {
                // seulement et dans cet ordre :
                // Cancers, Maladies chroniques, Maladies rares, Autres
                // 1) on clone les tags
                const clonedCategory = $clone(category)
                const subTags = clonedCategory.tags
                const displaidTags = []
                displaidTags.push(findTag('temoignages', subTags))
                displaidTags.push(findTag('associations', subTags))
                displaidTags.push(findTag('evenements', subTags))
                displaidTags.push(findTag('on-parle-de-nous-!', subTags))

                clonedCategory.tags = displaidTags.filter(Boolean)
                return clonedCategory
              },
            },
          },
        ],
        additionalRoutes: [
          {
            name: 'custom-partners',
            component: '~/excentrics/pages/custom-partners.vue',
            path: '/partners',
            chunkName: 'pages/partners',
          },
        ],
      },
    ],

    nuxt: {
      robots: {
        UserAgent: '*',
        Disallow: '',
      },
      head: {
        title:
          'Patients Ensemble, le programme podcast par et pour les patients',
        meta: [
          {
            charset: 'utf-8',
          },
          {
            name: 'viewport',
            content:
              'width=device-width, initial-scale=1, minimal-ui, shrink-to-fit=no',
          },
          {
            hid: 'description',
            name: 'description',
            content: `Partager les informations, témoignages, avis et conseils des associations de patients et des professionnels pour vous accompagner dans votre quotidien de patients`,
          },
          {
            'http-equiv': 'X-UA-Compatible',
            content: 'IE=edge',
          },
          {
            'http-equiv': 'X-UA-Compatible',
            content: 'IE=edge',
          },
          {
            charset: 'utf-8',
          },
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
          {
            hid: 'robots',
            name: 'robots',
            content: 'index,follow',
          },
          {
            hid: 'ogSiteName',
            name: 'og:site_name',
            content:
              'Patients Ensemble, le programme podcast par et pour les patients',
          },
          {
            hid: 'ogDescription',
            name: 'og:description',
            content:
              'Partager les informations, témoignages, avis et conseils des associations de patients et des professionnels pour vous accompagner dans votre quotidien de patients',
          },
          {
            hid: 'ogType',
            name: 'og:type',
            content: 'website',
          },
        ],
      },
    },
  },
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)
module.exports = transformConfig(endConfig)
